import axios from "axios";
import React, { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";
import Completion from "../components/Completion";
import EyeIcon from "../components/EyeIcon";
import { Divider, Tooltip } from "antd";
import { handlePayment } from "../api/stripePayment";
import { Helmet } from "react-helmet";
import { FcGoogle } from "react-icons/fc";
import { useGoogleLogin } from "@react-oauth/google";
function Login() {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("");
  const [surname, setsurName] = useState("");
  const [email, setEmail] = useState("");
  const [isValid, setIsValid] = useState(true);
  const [password, setPassword] = useState("");
  const [coupon, setcoupon] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const validateEmail = (email) => {
    // Regular expression for email validation
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailPattern.test(email);
  };

  const login = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      try {
        const { access_token } = tokenResponse;
        const response = await axios.get(
          `https://www.googleapis.com/oauth2/v3/userinfo`,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
            },
          }
        );
        const { email, name } = response.data;
        setEmail(email);
        setName(name);
        const isGoogle = true;
        handlePayment(
          name,
          surname,
          email,
          password,
          coupon,
          setLoading,
          isGoogle
        );
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    },
    onError: (errorResponse) => {
      console.log("Login Failed", errorResponse);
    },
  });

  const handleChange = (event) => {
    const inputValue = event.target.value;
    setEmail(inputValue);
    setIsValid(validateEmail(inputValue));
  };
  const handlePaymentClick = (e) => {
    e.preventDefault();
    handlePayment(name, surname, email, password, coupon, setLoading);
  };
  useEffect(() => {
    extractSurname();
  }, [name]);

  const extractSurname = () => {
    const nameParts = name.split(" ");
    const extractedSurname =
      nameParts.length > 0 ? nameParts[nameParts.length - 1] : "";
    setsurName(extractedSurname);
  };
  return (
    <>
      <Helmet>
        <script>
          {`
            fbq('track', 'CompleteRegistration');
          `}
        </script>
      </Helmet>
      <div className="h-[1024px] overflow-scroll">
        <div className="flex   justify-center items-center  mx-auto my-8">
          <img
            className="w-[252px] h-[70px]"
            src="/logo.png"
            alt=""
            srcSet=""
          />
          {/* <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1.5}
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z"
              />
            </svg>
          </div> */}
        </div>
        <hr />
        <div className=" w-full flex flex-col justify-center items-center    ">
          <Completion />

          <div className="h-[1200px]  my-12">
            <div className=" flex border-[#878787] border-[1px]">
              <div className="bg-white shadow-2xl md:pb-24 pb-4 mb-36 lg:p-8 px-8 boxx rounded-3xl max-w-[500px] h-full  md:mb-0">
                <p className="font-semibold mt-4" style={{ fontSize: 22 }}>
                  Werde Mitglied bei Kochguru - die Einkaufsgemeinschaft für
                  Geniesser! Jetzt kostenlos für 14 Tage testen!
                </p>
                <form className="my-6">
                  <div className="mb-6">
                    <label className="block mb-1">
                      Dein vollständiger Name
                    </label>
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="h-14 text-sm flex items-center border-[#878787] border-[1px] w-full pl-3"
                      placeholder="Vor- und Nachname"
                      required
                    />
                  </div>

                  {/* email */}
                  <div className="mb-6">
                    <label className="block mb-1">Deine E-Mail-Adress</label>
                    <input
                      type="email"
                      value={email}
                      onChange={handleChange}
                      style={{
                        borderColor: isValid
                          ? "border border-gray-300"
                          : "border-red",
                      }}
                      className="h-14 text-sm flex items-center border-[#878787] border-[1px] w-full pl-3 focus:outline-none"
                      placeholder="E-Mail-Adresse"
                      required
                    />
                  </div>
                  {/* password */}
                  <div className="mb-6">
                    <label className="block mb-1">Passwort</label>
                    <div className="flex px-3 h-14 text-sm items-center border-[#878787] border-[1px] w-full">
                      <input
                        type={showPassword ? "text" : "password"}
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        className="w-full text-sm focus:outline-none"
                        placeholder="Passwort "
                        required
                      />
                      <div onClick={() => setShowPassword(!showPassword)}>
                        <EyeIcon />
                      </div>
                    </div>
                  </div>
                  <div className="mb-12">
                    <div className="flex mb-1 items-center">
                      <label className="  flex mr-1">Gutschein</label>
                      <Tooltip title="Haben Sie einen Gutschein">
                        <span className="btn px-[5.5px] py-[0px] text-xs  rounded-full border-[1px] border-black">
                          i
                        </span>
                      </Tooltip>
                    </div>

                    <input
                      type="text"
                      value={coupon}
                      onChange={(e) => setcoupon(e.target.value)}
                      className="h-14 text-sm flex items-center border-[#878787] border-[1px] w-full pl-3 focus:outline-none"
                      placeholder="Gutschein-Code"
                      required
                    />
                  </div>

                  <button
                    onClick={(e) => handlePaymentClick(e)}
                    className="text-[#fff] w-full  text-[16px] font-[500] py-3 px-[18px] rounded-[8px] bg-[#F04F36]"
                  >
                    {loading ? "Bitte warten...." : " Jetzt kostenlos testen"}
                  </button>
                </form>

                <Divider style={{ borderBlock: "1px", borderColor: "grey" }}>
                  <div className="text-[#808080] font-[400]">Oder</div>
                </Divider>

                <div className="w-full">
                  <button
                    onClick={() => login()}
                    className="flex w-full mt-6 justify-center items-center space-x-2 bg-white text-gray-700 px-4 py-2 border border-gray-300 rounded shadow hover:bg-gray-100 transition duration-300 ease-in-out"
                  >
                    <FcGoogle size={24} />
                    <span>Weiter mit Google</span>
                  </button>
                </div>
                <div className="mt-8">
                  {/* <p className="text-[#1A1A1A99] text-center text-xs">
                    Für ein Jahresabonnement werden Ihnen CHF 96.- berechnet.
                    Sie können jederzeit im voraus kündigen
                  </p> */}
                  <p className="text-[#7A7A7A] text-center mt-3">
                    Bereits ein Konto?
                    <a
                      href="https://www.kochguru.ch/account/login"
                      className="text-[#F04F36] font-medium"
                    >
                      &nbsp;Hier einloggen
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Login;
