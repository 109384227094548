import React, { useEffect } from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import {Helmet} from "react-helmet"

function Success() {
  const [name, setName] = React.useState(null);
  const [email, setEmail] = React.useState(null);
  const [password, setPassword] = React.useState(null);
  const [surname, setsurName] = React.useState(null);
  const [isGoogle, setIsGoogle] = React.useState(null);
  const [uniqueid, setuniqueid] = React.useState(null);


  
  const navigate = useNavigate();

  const register = async () => {
    try {
      const data = await axios.post(
        `${process.env.REACT_APP_YOUR_API}/api/signup`,
        {
          name: name,
          email: email,
          password: password,
          surname: surname,
          uniqueid:uniqueid,
          isGoogle: isGoogle,
        }
      );

        toast.success("Successfully register for 7 day free trial!", {
          position: toast.POSITION.TOP_CENTER,
        });
        setInterval(() => {
          window.location.href = "https://www.kochguru.ch/pages/signin";
        }, 2000);
        const storedUserData = localStorage.removeItem("userdata");
        console.log("remove done",storedUserData);
      
      
    } catch (error) {
      toast.error(error.response.data.message, {
        position: toast.POSITION.TOP_CENTER,
      });
      navigate("/register");
    }
  };
  useEffect(() => {
    const storedUserData = localStorage.getItem("userdata");
    console.log("🚀  storedUserData:", storedUserData);
    if (storedUserData) {
      const userData = JSON.parse(storedUserData);
      console.log(
        "🚀 ~ file: Success.jsx:43 ~ useEffect ~ userData:",
        userData
      );
      setName(userData.name);
      setPassword(userData.password);
      setEmail(userData.email);
      setIsGoogle(userData.isGoogle);
      setsurName(userData.surname);
      setuniqueid(userData.uniqueid)
    }
    if (name && email && password) {
      console.log("🚀 ~ file: Success.jsx:47 ~ useEffect ~ name:", name);
      register();
    }
  }, [name, email, surname, password,uniqueid]);

  return (
    <div>
       <Helmet>
        <script>
          {`
            gtag('event', 'conversion', {'send_to': 'AW-16585536066/TQjyCJXZ7MMZEMLkzOQ9'});
          `}
        </script>
      </Helmet>
      <div
        tabindex="-1"
        aria-hidden="true"
        class="flex overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center w-full md:inset-0 h-modal md:h-full"
      >
        <div class="relative p-4 w-full max-w-md h-full md:h-auto">
          <div class="relative p-4 text-center bg-white rounded-lg shadow dark:bg-gray-800 sm:p-5">
            <div class="w-12 h-12 rounded-full bg-green-100 dark:bg-green-900 p-2 flex items-center justify-center mx-auto mb-3.5">
              <svg
                aria-hidden="true"
                class="w-8 h-8 text-green-500 dark:text-green-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="sr-only">Success</span>
            </div>
            <p class="mb-4 text-lg font-semibold text-gray-900 dark:text-white">
            Deine Anmeldung wurde erfolgreich verarbeitet! Vielen Dank und herzlich willkommen in der Kochguru-Community!
            </p>
            <Link to="/register" className="text-[#fff] mt-[34px] text-[16px] font-[500] py-[12px] px-[18px] rounded-[8px] bg-green-500">
              Zurück
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Success;
