import axios from "axios";
import { toast } from "react-toastify";

export const handlePayment = async (
  name,
  surname,
  email,
  password,
  coupon,
  setLoading,
  isGoogle = false
) => {
  try {
    if (isGoogle) {
      password = "12345678";
    }
    if (!isGoogle && (name === "" || email === "" || password === "")) {
      toast.error("Please fill all the fields", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const isValid = emailPattern.test(email);

    if (!isValid) {
      toast.error("Please enter a valid email", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    if (!isGoogle && password.length < 6) {
      toast.error("Password must be at least 6 characters", {
        position: toast.POSITION.TOP_CENTER,
      });
      return;
    }

    setLoading(true);

    const data = await axios.post(
      `${process.env.REACT_APP_YOUR_API}/create-checkout-session`,
      { email, name, coupon }
    );

    const userData = {
      name,
      surname,
      email,
      password,
      isGoogle,
      uniqueid: data.data.data,
    };

    localStorage.setItem("userdata", JSON.stringify(userData));
    window.location.href = data.data.url;
    setLoading(false);
  } catch (error) {
    console.error("Error in Stripe URL", error);
    setLoading(false);
    toast.error("Error in Stripe URL, Try again", {
      position: toast.POSITION.TOP_CENTER,
    });
  }
};