import React from 'react'
import RightArrowIcon from './RightArrowIcon'

const Completion = () => {
  return (
    <div className=''>
        <div className=' flex flex-col md:flex-row md:justify-between justify-start items-start  md:items-center p-2 md:[634px] flex-wrap bg-[#F5F3F1] px-6 py-4'>
          <div className='flex md:justify-center  items-center  md:mr-4 my-2'>
            
            
            <p className='px-[10px] py-2  rounded-full text-xs font-semibold text-white bg-[#F04F36] flex items-center justify-center mr-2'>01</p>
            {/* <hr className=' bg-[#E5E7EB] py-[1px] w-[70px] sm:w-[100px]  md:w-[214px] ' /> */}
            
            <p className='font-medium text-sm mr-3 w-full '>Persönliche Angaben</p>
            <div className='hidden md:flex'>
              <RightArrowIcon />
            </div>
          </div>
          
          <div className='flex   md:justify-center  items-center md:mr-4 my-2'>
            
            {/* <div className='flex items-center'> */}
            <p className='h-8 w-8 border-[#7A7A7A] border-[1px] rounded-full text-xs font-semibold text-[#7A7A7A] bg-white flex items-center justify-center mr-2'>02</p>
            {/* <hr className=' bg-[#E5E7EB] py-[1px] w-[70px] sm:w-[100px]  md:w-[214px]' /> */}
            {/* </div> */}
            <p className='font-medium text-sm mr-3 text-[#7A7A7A]'>Zahlungsdetails</p>
            <div className='hidden md:flex'>
              <RightArrowIcon />
            </div>
          </div>
          
          <div className='flex   md:justify-center  items-center my-2'>
            
            <p  className='h-8 w-8 border-[#7A7A7A] border-[1px] rounded-full text-xs font-semibold text-[#7A7A7A] bg-white flex items-center justify-center mr-2'>03</p>
            <p className='font-medium text-sm mr-3 text-[#7A7A7A]'>Anmelden</p>
          </div> 
        </div>
        
    </div>
  )
}

export default Completion