import { Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Success from "./pages/Success";
import Error from "./pages/Error";
import "./App.css";
import { GoogleOAuthProvider } from "@react-oauth/google";

export default function App() {
  return (
    <div className="overflow-x-hidden">
      <GoogleOAuthProvider clientId="150479378610-5jenkjl85c9njfs0ut3qjca3bfou0uhk.apps.googleusercontent.com">
        <Routes>
          <Route path="/register" element={<Login />} />
          <Route path="/success" element={<Success />} />
          <Route path="/error" element={<Error />} />
        </Routes>
      </GoogleOAuthProvider>
    </div>
  );
}
