import React from "react";

const EyeIcon = () => {
  return (
    <div>
      <svg
        width="18"
        height="18"
        viewBox="0 0 18 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.10244 11.4599C6.95994 11.4599 6.81744 11.4074 6.70494 11.2949C6.08994 10.6799 5.75244 9.86244 5.75244 8.99994C5.75244 7.20744 7.20744 5.75244 8.99994 5.75244C9.86244 5.75244 10.6799 6.08994 11.2949 6.70494C11.3999 6.80994 11.4599 6.95244 11.4599 7.10244C11.4599 7.25244 11.3999 7.39494 11.2949 7.49994L7.49994 11.2949C7.38744 11.4074 7.24494 11.4599 7.10244 11.4599ZM8.99994 6.87744C7.82994 6.87744 6.87744 7.82994 6.87744 8.99994C6.87744 9.37494 6.97494 9.73494 7.15494 10.0499L10.0499 7.15494C9.73494 6.97494 9.37494 6.87744 8.99994 6.87744Z"
          fill="#292D32"
        />
        <path
          d="M4.19939 13.8819C4.07189 13.8819 3.93689 13.8369 3.83189 13.7469C3.02939 13.0644 2.30939 12.2244 1.69439 11.2494C0.899395 10.0119 0.899395 7.99438 1.69439 6.74938C3.52439 3.88438 6.18689 2.23438 8.99939 2.23438C10.6494 2.23438 12.2769 2.80438 13.7019 3.87688C13.9494 4.06438 14.0019 4.41687 13.8144 4.66438C13.6269 4.91188 13.2744 4.96438 13.0269 4.77688C11.7969 3.84688 10.4019 3.35938 8.99939 3.35938C6.57689 3.35938 4.25939 4.81438 2.63939 7.35688C2.07689 8.23438 2.07689 9.76437 2.63939 10.6419C3.20189 11.5194 3.84689 12.2769 4.55939 12.8919C4.79189 13.0944 4.82189 13.4469 4.61939 13.6869C4.51439 13.8144 4.35689 13.8819 4.19939 13.8819Z"
          fill="#292D32"
        />
        <path
          d="M9.0009 15.7641C8.0034 15.7641 7.0284 15.5616 6.0909 15.1641C5.8059 15.0441 5.6709 14.7141 5.7909 14.4291C5.9109 14.1441 6.2409 14.0091 6.5259 14.1291C7.3209 14.4666 8.1534 14.6391 8.9934 14.6391C11.4159 14.6391 13.7334 13.1841 15.3534 10.6416C15.9159 9.76414 15.9159 8.23414 15.3534 7.35664C15.1209 6.98914 14.8659 6.63664 14.5959 6.30664C14.4009 6.06664 14.4384 5.71414 14.6784 5.51164C14.9184 5.31664 15.2709 5.34664 15.4734 5.59414C15.7659 5.95414 16.0509 6.34414 16.3059 6.74914C17.1009 7.98664 17.1009 10.0041 16.3059 11.2491C14.4759 14.1141 11.8134 15.7641 9.0009 15.7641Z"
          fill="#292D32"
        />
        <path
          d="M9.51719 12.2037C9.25469 12.2037 9.01469 12.0162 8.96219 11.7462C8.90219 11.4387 9.10469 11.1462 9.41219 11.0937C10.2372 10.9437 10.9272 10.2537 11.0772 9.42874C11.1372 9.12124 11.4297 8.92624 11.7372 8.97874C12.0447 9.03874 12.2472 9.33124 12.1872 9.63874C11.9472 10.9362 10.9122 11.9637 9.62219 12.2037C9.58469 12.1962 9.55469 12.2037 9.51719 12.2037Z"
          fill="#292D32"
        />
        <path
          d="M1.49959 17.063C1.35709 17.063 1.21459 17.0105 1.10209 16.898C0.88459 16.6805 0.88459 16.3205 1.10209 16.103L6.70459 10.5005C6.92209 10.283 7.28209 10.283 7.49959 10.5005C7.71709 10.718 7.71709 11.078 7.49959 11.2955L1.89709 16.898C1.78459 17.0105 1.64209 17.063 1.49959 17.063Z"
          fill="#292D32"
        />
        <path
          d="M10.8985 7.66508C10.756 7.66508 10.6135 7.61258 10.501 7.50008C10.2835 7.28258 10.2835 6.92258 10.501 6.70508L16.1035 1.10258C16.321 0.885078 16.681 0.885078 16.8985 1.10258C17.116 1.32008 17.116 1.68008 16.8985 1.89758L11.296 7.50008C11.1835 7.61258 11.041 7.66508 10.8985 7.66508Z"
          fill="#292D32"
        />
      </svg>
    </div>
  );
};

export default EyeIcon;
