import React from "react";

const RightArrowIcon = () => {
  return (
    <div>
      <svg
        width="35"
        height="8"
        viewBox="0 0 35 8"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M34.3536 4.35356C34.5488 4.15829 34.5488 3.84171 34.3536 3.64645L31.1716 0.464469C30.9763 0.269207 30.6597 0.269207 30.4645 0.464469C30.2692 0.659731 30.2692 0.976313 30.4645 1.17158L33.2929 4L30.4645 6.82843C30.2692 7.02369 30.2692 7.34027 30.4645 7.53554C30.6597 7.7308 30.9763 7.7308 31.1716 7.53554L34.3536 4.35356ZM-4.37114e-08 4.5L34 4.5L34 3.5L4.37114e-08 3.5L-4.37114e-08 4.5Z"
          fill="#222222"
        />
      </svg>
    </div>
  );
};

export default RightArrowIcon;
